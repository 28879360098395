<template>
  <component :is="layout">
    <router-view></router-view>
  </component>
</template>
<script>
const layout = "blank"

export default {
  name: "App",
  data : ()=>({
  }),
  computed: {
    layout(){
      return (this.$route.meta.layout || layout) + '-layout';
    }
  },
  /*created: function () {
    this.$http.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('logout')
          resolve();
        }
        throw reject(err);
      });
    });
  },
  mounted() {
    this.tab = this.tabs[0];
    Drequest.getuser().get((response)=>{
      console.log(response)
      this.user = response.user;
      this.ready = true;
      if(this.user.professional == 2)
        this.tabs.push({icon: "mdi-map-marker", key: "tracking", name: "Tracking", component:"tracking"});
    });
  }*/
}
</script>

<style scoped>
.v-dialog {
  border-radius: 5px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}
</style>