<template>
    <div>
    <v-row>
          <v-col cols="8" sm="12">
            <loaderdialog :loader="{text:'Traitement en cours', power: startload}" v-if="startload"/>
          <breadcrumbs :items="dataBreadcrumbs" />
          </v-col>
            <v-col cols="4" sm="12" align="right">
            </v-col>
        </v-row>
        <v-card elevation="0" class="my-8">
          <v-card-title>
            Cochez les pays deservis : 
          </v-card-title>
          <v-card-text style="max-height: 460px; overflow: auto">
            <v-checkbox v-for="item in items" :key="item.id"
                        v-model="item.checked"
                        @click="save(item)"
                        :label="`${item.name}`"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      <v-card class="mt-3" elevation="0">
        <!-- <v-btn
            small
            v-if="n!= 1"
            color="primary"
            @click="prevStep(n)"
            class="float-left"
        >
          Précédant
        </v-btn> -->
        <v-btn
            small
            v-if="n!= 5"
            color="primary"
            @click="nextStep(2)"
            class="float-right"
        >
          Continue
        </v-btn>
      </v-card>
    </div>

</template>

<script>
import breadcrumbs from "../../components/ui/breadcrumbs";
import loaderdialog from "../../components/ui/loaderdialog";
import {Drequest} from "../../plugins/Drequest";

export default {
  name: "country-check",
  props: ["nextStep"],
  components: {
    breadcrumbs,
    loaderdialog
  },
  data: () => ({
    startload: false,
    dataBreadcrumbs: [],
    transporter: {},
    dialog: false,
    dialogDelete: false,
    items: [],
  }),
  computed: {
  },

  created() {
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'Couverture Livraison',
        disabled: true,
        href: '/settings/course',
      }
    ];
    this.initialize()
  },

  methods: {
    initialize() {
      this.startload = true;
      this.transporter = this.$store.state.transporter;
      this.$store.dispatch('countriesCheck').then((res) => {
        this.items = res;
      this.startload = false;
      })
    },

    deleteItemConfirm(countryid) {
      var fd = new FormData();
      fd.append("id_transporter", this.transporter.id);
      fd.append("id_country", countryid);

      Drequest.api("transporter-removecountry").data(fd).post((response)=>{
        this.startload = false;
        console.log(response);
      })
    },

    save(country) {
      this.startload = true;
      if(!country.checked){
        this.deleteItemConfirm(country.id)
        return 0;
      }
      let bodyFormData = new FormData();
        bodyFormData.append("id_transporter", this.$store.state.transporter.id);
        bodyFormData.append("id_country", country.id);
        Drequest
            .api("transporter-addcountry")
            .data(bodyFormData)
            .post((response)=>{
                console.log(response)
                this.startload = false;
        })
    },
  },
}
</script>

<style scoped>

</style>