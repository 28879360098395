<template>
  <div>
    <v-row>
        <v-col xs="12" class="col-xs-12">
          <h2 style="font-size: 25px !important; text-align:left;">Intervalles de Poids</h2>
        </v-col>
        <v-col xs="12" class="col-xs-12">
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2 float-right"
                  v-bind="attrs"
                  v-on="on"
              >
              <v-icon small>
                      mdi-plus
                    </v-icon>
                Nouveau
              </v-btn>
            </template>

            <v-card elevation="0">
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
            <v-form @submit.prevent="save">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="12"
                    >
                      <v-text-field
                          v-model="editedItem.label"
                          label="Libelle"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          v-model="editedItem.min"
                          label="Valeur min"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="6"
                    >
                      <v-text-field
                          v-model="editedItem.max"
                          label="Valeur max"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                  <v-btn
                    color="error"
                    text
                    @click="close"
                  >
                    Fermer
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      :loading="loading"
                      :disabled="loading"
                      color="primary"
                      dark
                      type="submit"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-content-save-check
                    </v-icon>
                    Enregistrer
                  </v-btn>
              </v-card-actions>
            </v-form>
            </v-card>
          </v-dialog>
        </v-col>
    </v-row>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Êtes-vous sûr de bien vouloir supprimer ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="success" text @click="deleteItemConfirm">Ok</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text elevation="0">
    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-row>
        <v-col lg="6">
          <v-text-field v-model="searchQuery" label="Filtre"></v-text-field>
        </v-col>
        <!-- v-col lg="2">
          <v-select
              label="Statut"
              v-model="search.status"
              :items="statues"
              item-value="id"
              item-text="label"
              @change="fetchItems"
          ></v-select>
        </v-col -->
        <v-col lg="3">
          <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="interval.date1"
                  label="Du"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="interval.date1"
                @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col lg="3">
          <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="interval.date2"
                  label="Au"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="interval.date2"
                @input="menu3 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

      </v-row>

      <v-spacer></v-spacer>

      <div class="d-flex align-center flex-wrap">

      </div>
    </v-card-text>
    <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items.listEntity"
        class="elevation-0"
        :page.sync="items.current_page"
        :items-per-page="items.per_page"
        :sort-by="['id']"
        @page-count="pageCount = $event"
        hide-default-footer
        :sort-desc="true"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          right
          color="warning"
          dark
          class="mx-1 text-right"
          @click="editItem(item)"
      >
        <v-icon
              small
          >
          mdi-pencil
        </v-icon>
      </v-btn>
        <v-btn
          right
          color="error"
          dark
          class="mx-1 text-right"
          @click="deleteItem(item)"
          
      >
        <v-icon
            small
        >
          mdi-delete
        </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-card-text class="pt-2">
      <v-row>
        <v-col
            lg="2"
            cols="3"
        >

          <v-select
              v-model="items.per_page"
              label="Lignes par page:"
              :items="[10,20,30,50,80,100, 'Toutes']"
              hide-details
              @change="fetchItems()"
          ></v-select>

        </v-col>

        <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
        >
          <!-- <v-pagination
              v-model="items.current_page"
              total-visible="6"
              :length="parseInt(items.pagination)"
              @input="fetchItems()"
          ></v-pagination> -->
        </v-col>
      </v-row>
    </v-card-text>
      <v-card class="mt-3" elevation="0">
        <!-- <v-btn
            small
            v-if="n!= 1"
            color="primary"
            @click="prevStep(n)"
            class="float-left"
        >
          Précédant
        </v-btn> -->
        <v-btn
            small
            v-if="n!= 5"
            color="primary"
            @click="nextStep(4)"
            class="float-right"
        >
          Continue
        </v-btn>
      </v-card>
  </v-card-text>
  </div>
</template>

<script>
import { mdiAlertRemove, mdiThumbUp } from '@mdi/js'
import {Drequest} from "@/plugins/Drequest";
const moonLanding = new Date();

export default {
  name: "interval-weight",
  data: () => ({
    items: [],
    transporter: {},
    interval: {
      date1: moonLanding.getFullYear() + "-01-01",
      date2: moonLanding.getFullYear() + "-12-31"
    },
    searchQuery: "",
    menu2: false,
    menu3: false,
    icons: {
        mdiAlertRemove,
        mdiThumbUp
      },
      alert:{
        color: '',
        text:'',
        icons:'',
        active: false
      },
      loading: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Libelle',
        align: 'start',
        sortable: false,
        value: 'label',
      },
      {text: 'Valeur Min', value: 'min'},
      {text: 'Valeur Max', value: 'max'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    editedIndex: -1,
    editedItem: {
      label: '',
      min: 0,
      max: 0
    },
    defaultItem: {
    },
  }),
  props: ["nextStep"],
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouvelle Intervalle de Prix' : 'Editer un Interval de Prix'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    fetchItems() {
      this.search = {
        'tree_item.name:in': [],
      }
      this.loading = true;
      Drequest.api("lazyloading.weight-interval?dfilters=on&transporter.id:eq=" + this.$store.state.transporter.id)
          .param({
            next: this.items.current_page,
            per_page: this.items.per_page,
          })
          .get((response) => {
            console.log(response);
            this.items = response;
            this.loading = false;
          })
    },
    initialize() {
      this.loading = true;
      this.transporter = this.$store.state.transporter;
      this.$store.dispatch('initIntervalWeight').then((res) => {
        this.items = res;
        this.loading = false;
      })
    },

    editItem(item) {
      this.editedIndex = this.items.listEntity.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.listEntity.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const promise = this.$store.dispatch('deleteWeightInterval', this.editedItem)
            promise.then((res) => {
              console.log(res)
               this.items.listEntity.splice(this.editedIndex, 1)
               this.closeDelete()
            })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.loading = true;
      if (this.editedIndex > -1) {
        const promise = this.$store.dispatch('updateWeightInterval', this.editedItem)
            promise.then((res) => {
              console.log(res)
              //this.items[this.editedIndex] = res.weight_interval
              this.initialize();
               this.close()
            })
            .catch(err => {
              this.alert = {
                color: "error",
                active: true,
                text: "Erreur lors de l'enregistrement",
                icons: this.icons.mdiAlertRemove
              }
              console.log(err)
            })
      } else {
          const promise = this.$store.dispatch('saveWeightInterval', {weight_interval:this.editedItem})
            promise.then((res) => {
               this.alert = {
                color: "success",
                active: true,
                text: res.weight_interval.label,
                icons: this.icons.mdiThumbUp
              }
              this.initialize()
              this.close()
            })
            .catch(err => {
              this.alert = {
                color: "error",
                active: true,
                text: "Erreur lors de l'enregistrement",
                icons: this.icons.mdiAlertRemove
              }
              console.log(err)
            })
      }
    },
  },
}
</script>

<style scoped>

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  width: auto;
  font-weight: 600;
  padding: 10px;
  color: white;
}

.interval-price-table{
  margin: 0 15%;
}
.v-card__title {
  display: inline-grid !important;
}

.v-tabs-bar {
  background-color: transparent !important;
  margin-bottom: 10px;
}

.v-card__text .pt-2{
  background-color: #f4f5fa !important;
}
</style>