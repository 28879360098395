import $ from 'jquery';

export class Drequest {
    static __env = $(document).find("html").data("api");
    static __client = $(document).find("html").data("client");
    static __user = null //JSON.parse($(document).find("#data-user").text());
    baseurl = Drequest.__env;
    url = '';
    _param = '' //{iduser:Drequest.__user.id};
    _data = {};
    /*static getuser(){
        return Drequest.api("detail.user?id="+Drequest.__user.id)
    }*/
    constructor() {

    }

    static init(url) {
        let r = new Drequest();
        r.baseurl = url;
        r.url = '';
        return r;
    }

    static api(url) {
        let r = new Drequest();
        r.baseurl = Drequest.__env+"api/"+url;
        console.log(r.baseurl)
        return r;
    }

    static localstorage(){
        if (localStorage.getItem('aggregator')) {
            try {
                return  JSON.parse(localStorage.getItem('aggregator'));
            } catch(e) {
                localStorage.removeItem('aggregator');
            }
        }
        return {};
    }
    static localsave(database){
        localStorage.setItem('aggregator', JSON.stringify(database))
    }

    param(_param) {
        this._param = _param;
        return this;
    }

    data(_data) {
        this._data = _data;
        return this;
    }
    toFormdata(_data) {
        var keys = Object.keys(_data);
        var values = Object.values(_data);

        var fd = new FormData();
        for (var i = 0; i < keys.length; i++) {
            if (typeof values[i] === 'object' && values[i] !== null)
                fd.append(keys[i], values[i].id)
            else
                fd.append(keys[i], values[i])
        }
        this._data = fd;
        return this;
    }
    toDevupsFormdata(entity, _data, fd) {
        var key = '';
        var keys = Object.keys(_data);
        var values = Object.values(_data);

        if(!fd)
            fd = new FormData();

        for (var i = 0; i < keys.length; i++) {
            key = entity+'_form['+keys[i]+']';
            if (typeof values[i] === 'object' && values[i] !== null)
                fd.append(key, values[i].id)
            else
                fd.append(key, values[i])
        }
        this._data = fd;
        return this;
    }

    post(callback) {
        console.log(this);
        //console.log(this.baseurl + this.url+ $.isEmptyObject(this._param)? "": "?"+ $.param(this._param));
        var keys = Object.keys(this._param);
        var param = (!keys.length) ? "": "?"+ $.param(this._param);
        return $.ajax({
            url: this.baseurl + this.url+ param,
            data: this._data,
            cache: false,
            contentType: false,
            processData: false,
            method: "POST",
            dataType: "json",
            success: callback,
            error: function (e) {
                console.log(e);//responseText
            }
        });
    }

    raw(callback) {
        this._data = JSON.stringify(this._data);
        return this.post(callback);
    }

    get(callback, options = {}) {
        console.log(this._param)
        return $.ajax({
            url: this.baseurl + this.url, //+ $.isEmptyObject(this._param)? "": "?"+ $.param(this._param),
            data: this._param,
            method: "GET",
            dataType: "json",
            success: callback,
            error: function (e) {
                console.log(e);//responseText
            },
            ... options
        });
    }
}