<template>
  <v-app>
    <v-main>
      <select v-model="language" @change="changeLanguage">
        <option value="fr">
          FR
        </option>
        <option value="en">
          EN
        </option>
      </select>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import i18n from "@/i18n";
import {mdiAccountCircle, mdiAccountPlusOutline, mdiApple, mdiCubeSend, mdiGooglePlay} from "@mdi/js";

export default {
  data: () => ({
    icons: { mdiAccountPlusOutline, mdiAccountCircle, mdiGooglePlay, mdiApple, mdiCubeSend},
    language: localStorage.getItem('lang')
  }),
  methods: {
    changeLanguage(){
      localStorage.setItem('lang', this.language);
      window.location.reload();
    }
  },
  created() {
    if(localStorage.getItem('lang') == null){
      localStorage.setItem('lang', i18n.locale);
    }
  }
}
</script>

<style>
</style>
