<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
    >
      <div class="boxed-container w-full header">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>


          <v-spacer></v-spacer><!-- Right Content -->
          <select v-model="language" @change="changeLanguage">
            <option value="fr">
              FR
            </option>
            <option value="en">
              EN
            </option>
          </select>

          <v-btn
              style="color: white"
              @click="orderTaxi"
              class="primary ms-3"
              small
          > Commander
          </v-btn>

          <v-btn
              style="color: white"
              @click="sendcolis"
              class="primary ms-3"
              small
          ><v-icon size="30">{{ icons.mdiCubeSend }}</v-icon> Envoyer un Colis
          </v-btn>

          <!-- Right Content -->
          <div class="text-center">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                    :content="notifications.length"
                    :value="notifications.length"
                    color="error"
                    overlap
                >

                  <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      class="ms-3"
                  >

                    <v-icon>
                      {{ icons.mdiBellOutline }}
                    </v-icon>
                  </v-btn>
                </v-badge>
              </template>

              <v-card>
                <v-list>
                  <v-list-item>
                    <v-list-item-content v-for="notif in notifications" :key="notif.id">
                      <v-list-item-title>{{notif.notification.content}}</v-list-item-title>
                      <v-list-item-subtitle>{{notif.notification.content}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6 pt-0 mt-2">
        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import i18n from "@/i18n";
import {
  mdiMagnify,
  mdiBellOutline,
  mdiGithub
} from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
//import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import {Drequest} from "../plugins/Drequest";
import {mdiCubeSend} from '@mdi/js'
export default {
  components: {
    VerticalNavMenu,
    //ThemeSwitcher,
    AppBarUserMenu,
  },
  data : ()=>({
    language: localStorage.getItem('lang'),
    fav: true,
    menu: false,
    message: false,
    hints: true,
      notifications: [],
      isDrawerOpen:null,
      // Icons
      icons: {
        mdiCubeSend,
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
  }),
  mounted() {
    this.loadNotifications();
  },
  methods: {
    changeLanguage(){
      localStorage.setItem('lang', this.language);
      window.location.reload();
    },
    orderTaxi: function(){
      this.$router.push('/sendodertaxi');
    },
    sendcolis: function(){
      this.$router.push('/sendcolis');
    },
    loadNotifications: function (){
      Drequest.api("lazyloading.notificationbroadcasted?dfilters=on&user.id:eq="+JSON.parse(this.$store.state.user).id)
          .get((response) => {
            this.notifications = response.listEntity;
          })
    }
  },
  created() {
    if(localStorage.getItem('lang') == null){
      localStorage.setItem('lang', i18n.locale);
    }
  }
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;
    height: 64px;
    width: 100%;
    display: block;
    top: 20px;
    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
}
</style>
