<template>
  <div>
    <v-breadcrumbs :items="items"></v-breadcrumbs>
  </div>
</template>


<script>
  export default {
    props: ['items'],
  }
</script>