<template>

  <v-sheet
      height="auto"
      class="overflow-hidden"
    style="position: relative;"
  >
  <div class="auth-wrapper auth-v1">
    <loaderdialog :loader="{text:'Chargement de la carte', power: startload}" v-if="startload"/>
    <div v-else class="content-inner">
        <v-row>
          <v-col cols="8">
          <breadcrumbs :items="dataBreadcrumbs" />
          </v-col>
            <v-col cols="4" align="right">
              <div @click.stop="drawer = !drawer">
                <v-icon
                    large
                    color="blue-grey darken-2"
                >
                  mdi-call-split
                </v-icon>
              options
                </div>
            </v-col>
            <v-navigation-drawer
              v-model="drawer"
              absolute
              right
              temporary
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Options de la Maps</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list dense>
              <v-list-item
                  v-for="item in itemsDrawer"
                  :key="item.title"
                  link
              >
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>

        <v-col cols="12">
          <template>
            <v-toolbar
                dark
                color="teal"
            >
              <v-toolbar-title>Rechercher un lieu </v-toolbar-title>
              <v-autocomplete
                  v-model="select"
                  :loading="loading"
                  :items="itemsSearch"
                  :search-input.sync="search"
                  item-text="name"
                  item-value="geometry"
                  cache-items
                  class="mx-4"
                  hide-details
                  chips
                  clearable
                  hide-selected
                  label="Entrer un lieu"
                  solo
              ></v-autocomplete>
              <v-toolbar-title v-if="distanceMatrix.distance">D: {{distanceMatrix.distance.text}},  </v-toolbar-title>
              <v-toolbar-title v-if="distanceMatrix.duration">T: {{distanceMatrix.duration.text}} </v-toolbar-title>
            </v-toolbar>
          </template>
        </v-col>
      </v-row>
        <v-card elevation="0">
          <GmapMap
            :center="{lat:parseFloat(lat) ? parseFloat(lat) : latd , lng:parseFloat(lng) ? parseFloat(lng) : lngd}"
            :zoom="6"
            map-type-id="terrain"
            style="width: 100%; height: 800px"
        >
          <gmap-info-window :options="infoOptions" :position="infoPosition" :opened="infoOpened" @closeclick="infoOpened=false">
            <div v-html="infoContent"></div>
          </gmap-info-window>
          <GmapMarker
              :key="index"
              v-for="(gmp, index) in locations"
              :position="getPosition(gmp)"
              :clickable="true"
              @click="toggleInfo(gmp, index)"
              :icon="gmp.icon"
          >{{gmp}}</GmapMarker>
        </GmapMap>
        </v-card>
    </div>
  </div>
  </v-sheet>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {Drequest} from "../../plugins/Drequest";
import affiliationIcon from "../../assets/icons/delivery-50.png";
import colisIcon from "../../assets/icons/colis-50.png";
import loaderdialog from "../../components/ui/loaderdialog";
import breadcrumbs from "../../components/ui/breadcrumbs";
export default {
  icons: {
    affiliationIcon,
    colisIcon
  },
  components: {
    loaderdialog,
    breadcrumbs
  },
  name: "Map",
  props: [],
  watch: {
    search (val) {
      val && val !== this.select && this.querySelections(val)
    },
    select(val){
      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(
            position => {
              this.getDistanceMatrix({lat:position.coords.latitude, lng:position.coords.longitude}, {lat:val.lat, lng:val.lng})
            },
            error => {
              console.log(error.message);
            }
        )
      }else{
        console.log("your browser does not support geolocalisation");
      }
    }
  },
  data: () => ({loading: false,
    itemsSearch: [],
    search: null,
    select: null,
    drawer: null,
    itemsDrawer: [
      { title: 'Home', icon: 'mdi-view-dashboard' },
      { title: 'About', icon: 'mdi-forum' },
    ],
    dataBreadcrumbs: [],
    startload: true,
    lat: null,
    lng: null,
    latd: 3.8355234383648824,
    lngd: 11.50565942946632,
    itemOrder: [],
    tracking: {},
    distanceMatrix: {},
    center: {},
    statues: [],
    affiliatell: [],
    locations: [],
    orderll: [],
    itemsAffiliatell: [],
    items: [{href:"setting/course", disabled: false, text:"Liste des Course"}],
    infoPosition: null,
    infoContent: null,
    infoOpened: false,
    infoCurrentKey: null,
    infoOptions: {
      pixelOffset: {
        width: 0,
        height: -35
      }
    },
  }),
  methods: {
    getPlaceFrom(place){
      let results = [];
      Drequest.init("https://maps.googleapis.com/maps/api/place/textsearch/json?query="+place+"&fields=formatted_address,name&key=AIzaSyBdHtCszsxBCg5EZnF6uXNuucr--mHoelg")
          .get((response) => {
            if(response.status && response.status == 'OK'){
              response.results.forEach((value) => {
                results.push({name:value.name, geometry:value.geometry.location});
                //console.log(value);
              });
              console.log(results);
              this.itemsSearch = results;
            }else{
              alert('erreur');
            }
          })
    },
    getDistanceMatrix(origin, destination){
      Drequest.init("https://maps.googleapis.com/maps/api/distancematrix/json?units=kilometer&origins="+origin.lat+","+origin.lng+"&destinations="+destination.lat+","+destination.lng+"&key=AIzaSyBdHtCszsxBCg5EZnF6uXNuucr--mHoelg")
          .get((response) => {
            this.distanceMatrix = response.rows[0].elements[0];
          })
    },
    querySelections(v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.getPlaceFrom(v);
        this.loading = false
      }, 500)
    },
    getPosition: function(marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng)
      }
    },
    toggleInfo: function(marker, key) {
      this.infoPosition = this.getPosition(marker);
      switch (marker.type) {
        case 'order':
          this.infoContent = '<div id="content">' +
              '<div id="siteNotice">' +
              `<h6 id="firstHeading" class="firstHeading">Code Tracking: ${marker.val.tracking_code}</h6>` +
              `<h6 id="firstHeading" class="firstHeading">montant denvoi du colis: ${marker.val.totalprice} FCFA</h6>` +
              `<p>date d'enregistrement : <b>${marker.val.created_at}</b></p>` +
              `<hr>`+
              '<h6 id="firstHeading" class="firstHeading">Emetteur</h6>' +
              '<div id="bodyContent">' +
              `<p>Nom : <b>${marker.val.sender.firstname}</b>` +
              `<p>Prénom : <b>${marker.val.sender.lastname}</b>` +
              `<p>Téléphone : <b>${marker.val.sender.phonenumber}</b>` +
              "</div>" +
              '<h6 id="firstHeading" class="firstHeading">Récepteur</h6>' +
              '<div id="bodyContent">' +
              `<p>Nom : <b>${marker.val.receiver.firstname}</b>` +
              `<p>Prénom : <b>${marker.val.receiver.lastname}</b>` +
              `<p>Téléphone : <b>${marker.val.receiver.phonenumber}</b>` +
              `<hr>`+
              "</div>" +
              `<p><a style="color: #ffc107; font-weight: 600 !important;" href="/settings/course/${marker.val.id}">` +
              "détail</a> " +
              "</p>" +
              "</div>";
          break;

        case 'affiliate':
          this.infoContent = '<div id="content">' +
              '<div id="siteNotice">' +
              `<h6 id="firstHeading" class="firstHeading">Nombre de Colis: ${marker.val.count_colis}</h6>` +
              `<h6 id="firstHeading" class="firstHeading">Type véhicule: ${marker.val.chassi}</h6>` +
              `<hr>`+
              '<h6 id="firstHeading" class="firstHeading">Identifiants</h6>' +
              '<div id="bodyContent">' +
              `<p>Nom : <b>${marker.val.user.firstname}</b>` +
              `<p>Prénom : <b>${marker.val.user.lastname}</b>` +
              `<p>Téléphone : <b>${marker.val.user.phonenumber}</b>` +
              "</div>" +
              `<p><a style="color: #ffc107; font-weight: 600 !important;" href="/settings/affiliate/${marker.val.id}">` +
              "détail</a> " +
              "</p>" +
              "</div>";
          break;
      }
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
      }
    },
    mountLocation(affiliates, orders){
      let affiliateArray = [];
      let ordersArray = [];

      affiliates.forEach((value) => {
        if(value.latitude != null && value.longitude != null){
          let location = {
            lat: value.latitude,
            lng: value.longitude,
            icon: affiliationIcon,
            type: "affiliate",
            color: "#104f94",
            val: value,
            full_name: `Nom affiliate : ${value.user.firstname}, ${value.user.lastname}; Type véhicule : ${value.chassi}; Nombre de colis : ${value.count_colis};`
          }
          affiliateArray.push(location);
        }
      });

      //console.log(affiliateArray);

      orders.forEach((value, index) => {
        if(value.latitude != null && value.longitude != null){
          switch (index) {
            case 0:
              this.lng = value.longitude;
              this.lat = value.latitude;
              break;
          }
          let location = {
            lat: value.latitude,
            lng: value.longitude,
            icon: colisIcon,
            color: "#edad07",
            val: value,
            type: "order",
            full_name: `Nom expediteur : ${value.sender.lastname}, ${value.sender.lastname}; Nom receveur : ${value.receiver.lastname}, ${value.receiver.lastname};  Code Traking: ${value.tracking_code};  Date denregistrement: ${value.created_at};  Montant : ${value.tarrif.cost} FCFA `
          }
          ordersArray.push(location);
        }
      });
      //console.log(ordersArray);
      this.locations = ordersArray.concat(affiliateArray);

    },
    resourcebyTransporter() {
      let transporterId = this.$store.state.transporter.id;
      Drequest.api("dcollection")
          .data({
            "lazyloading.affiliate": "dfilters=on&transporter.id:eq=" + transporterId,
            "lazyloading.status": "dfilters=on&entity.name:eq=affiliate",
            "lazyloading.order":"dfilters=on&transporter.id:eq=" + transporterId
          })
          .raw((response) => {
            this.mountLocation(response["affiliate_ll"].listEntity, response["order_ll"].listEntity);
            this.affiliatell = response["affiliate_ll"];
            this.statues = response["status_ll"].listEntity;
            this.orderll = response["order_ll"].listEntity;
            this.itemsAffiliatell = this.affiliatell.listEntity;
            this.startload = false;
            // this.close();
          });
    },
  },
  async beforeMount() {
    this.dataBreadcrumbs = [
      {
        text: 'Accueil',
        disabled: false,
        href: '/dashboard',
      },
      {
        text: 'gestion de la google maps',
        disabled: true,
        href: '/settings/course',
      }
    ];
    await this.resourcebyTransporter();
  }
}

</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
#content {
  background-color: #104f94;
  max-width: 300px;
  color: white;
  padding-top: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0px !important;
  line-height: 2px !important;
}
.gm-style-iw-c {
  background-color: #104f94 !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
  background-color: #104f94  !important;
}

.gm-style .gm-style-iw-t::after {
  background: #104f94  !important;
}
.gm-ui-hover-effect {

  background: white !important;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  top: 0px;
  right: 0px;
  width: 30px !important;
  height: 30px !important;
  color: white !important;
}

.content-inner {
  width: 100% !important;
  z-index: 1;
}
.theme--light.v-sheet--outlined {
  border: none !important;
  /* border: thin solid rgba(94, 86, 105, 0.14); */
}
</style>