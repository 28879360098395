<template>
  <div>

    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col lg="12">
                <v-select
                    :items="countries"
                    item-value="iso"
                    @change="getCities"
                    item-text="name"
                    outlined
                    dense
                    label="Pays" v-model="editedItem['countryiso']">
                </v-select>
              </v-col>
              <v-col lg="12">

                <v-autocomplete
                    v-model="editedItem.cityname"
                    :items="cities"
                    :search-input.sync="searchcity"
                    :loading="isDestinationLoading"
                    outlined
                    dense
                    item-value="name"
                    item-text="name"
                    chips
                    small-chips
                    label="Selectionner un ou plusieurs ville de départ"
                ></v-autocomplete>

              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
              >
                <v-text-field
                    v-model="editedItem.name"
                    label="Nom de l'agence (Point relais)"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.address"
                    label="Adresse"
                ></v-text-field>
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
              >
                <v-text-field
                    v-model="editedItem.contact"
                    label="Contact"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="ma-2"
              outlined
              color="error"
              @click="close"
              :loading="loading"
          >
            Annuler
          </v-btn>
          <v-btn
              color="primary"
              class="me-3"
              @click="save"
              :loading="loading"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
        :headers="headers"
        item-key="full_name"
        class="table-rounded interval-relaypoint-table"
        disable-sort
        :items="items"
        :page.sync="agencyll.current_page"
        :items-per-page="agencyll.per_page"
        :sort-by="['id']"
        @page-count="pageCount = $event"
        hide-default-footer
        :sort-desc="true"
        :loading="loading"
    >

      <template v-slot:item.actions="{ item }">
        <v-btn
            class="mr-2"
            small
            @click="editItem(item)" >
          <v-icon>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
            class="mr-2"
            small
            @click="deleteItem(item)" >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>Agence / Point relais</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>


        </v-toolbar>
      </template>
      <template v-slot:no-data>
        Aucun point de relai disponible
      </template>
    </v-data-table>

  </div>
</template>

<script>
import {Drequest} from "../plugins/Drequest";

export default {
  name: "relay-point",
  data: () => ({
    agencyll: {},
    loading: false,
    dialog: false,
    dialogDelete: false,
    isDestinationLoading: false,
    headers: [
      {
        text: 'Pays',
        align: 'start',
        sortable: false,
        value: 'country.name',
      },
      {
        text: 'Ville',
        align: 'start',
        sortable: false,
        value: 'cityname',
      },
      {
        text: 'Nom',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {text: 'Contact', value: 'contact'},
      {text: 'Adresse', value: 'address'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    items: [],
    cities: [],
    searchcity: "",
    editedIndex: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
  }),
  props: ["transporter", "countries"],
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouvelle Agence' : 'Editer une Agence'
    },
  },

  watch: {
    searchcity(val) {
      console.log(val)
      this.getCitiesByName(val)
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.loading = true;
      Drequest.api("lazyloading.agency?dfilters=on&transporter.id:eq=" + this.transporter.id)
          .get((response) => {
            this.loading = false;
            this.agencyll = response
            this.items = this.agencyll.listEntity
          })

    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.getCities()
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      Drequest.api("delete.agency?id=" + this.editedItem.id).raw((response) => {
        console.log(response);

        this.items.splice(this.editedIndex, 1)
        this.closeDelete()

      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.loading = true;
      if (this.editedIndex > -1) {
        Drequest.api("update.agency?id=" + this.editedItem.id).data({agency: this.editedItem}).raw((response) => {
          console.log(response);
          this.initialize();
          this.loading = false;
          // this.items[this.editedIndex] = response.agency
          this.close()
        })
      } else {
        this.editedItem['transporter.id'] = this.transporter.id;
        Drequest.api("create.agency").data({agency: this.editedItem}).raw((response) => {
          console.log(response);
          this.loading = false;
          this.items.push(response.agency);
          this.close()
        })
      }
    },

    getCities() {
      this.isDestinationLoading = true
      Drequest.api("city.fetch?country_code=" + this.editedItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.cities = response.cities;
                this.isDestinationLoading = false
              }
          )
    },
    getCitiesByName(name) {
      if (!name)
        return 1;

      if (name.length < 2 || this.isDestinationLoading)
        return 1;

      this.isDestinationLoading = true
      Drequest.api("city.fetch?name=" + name + "&country_code=" + this.editedItem['countryiso'])
          .get((response) => {
                console.log(response)
                this.cities = [...this.cities, ...response.cities];
                this.isDestinationLoading = false
              }
          )

    },
  },
}
</script>

<style scoped>

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  width: auto;
  font-weight: 600;
  padding: 10px;
  color: white;
}

.interval-relaypoint-table {
  margin: 0 5%;
}
</style>