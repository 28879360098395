<template>
  <div>
    <v-card-text class="">
      <v-card-title class="text-sm">Créer mon compte professionel</v-card-title>
      <v-card-text>
        <v-alert
            v-show="alert.active"
            dismissible
            :color="alert.color"
            border="left"
            elevation="2"
            colored-border
            :icon="alert.icons"
        >
          {{ alert.text }}
        </v-alert>
        <v-form
            ref="form"
            @submit.prevent="validate"
        >
          <v-row>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  v-model="transporter.name"
                  hide-details
                  class="mb-3"
                  outlined
                  label="Name"
                  required
              ></v-text-field>

              <v-text-field
                  hide-details
                  class="mb-3"
                  outlined
                  v-model="transporter.telephone"
                  label="Numéro de téléphone"
                  required
              ></v-text-field>

            </v-col>
            <v-col
                cols="12"
                md="6"
            >
              <v-text-field
                  hide-details
                  class="mb-3"
                  outlined
                  v-model="transporter.email"
                  :rules="emailRules"
                  label="E-mail"
                  required
              ></v-text-field>

              <v-text-field
                  hide-details
                  class="mb-3"
                  outlined
                  v-model="transporter.licence"
                  label="Numéro de licence"
                  required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-select
              hide-details
              class="mb-3"
              outlined
              :items="social_reasons"
              item-value="id"
              item-text="name"
              label="Raison sociale" v-model="transporter['tree_item\\social_reason.id']">

          </v-select>

          <div class="d-flex align-center justify-space-between flex-wrap">
            <v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || 'You must agree to continue!']"
                label="J'accept les conditions"
                required
            ></v-checkbox>
          </div>

          <v-btn
              block
              color="success"
              class="mt-6"
              :loading="loading"
              type="submit"
          >
            Créer mon compte transporteur
          </v-btn>

        </v-form>

      </v-card-text>
    </v-card-text>
  </div>
</template>

<script>

import {mdiAlertRemove, mdiThumbUp} from '@mdi/js'

export default {
  name: "RegisterPro",
  data: () => ({
    tab: {},
    loading: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    social_reasons: [],
    checkbox: true,
    valid: true,
    dialog: false,
    response: {},
    transporter: {},
    icons: {
      mdiAlertRemove,
      mdiThumbUp
    },
    items: [
      {key: "transporter", name: "J'ai une licence de transporteur"},
      {key: "affiliate", name: "Je veux être un affiliate"},
    ],
    inprocess: false,
    alert: {
      color: '',
      text: '',
      icons: mdiThumbUp,
      active: false
    },
  }),
  props: ["user","nextStep"],
  beforeMount() {
    this.loadRaison();
    this.transporter = this.$store.state.transporter
    console.log(this.transporter)
  },
  methods: {
    loadRaison: function () {
      const promise = this.$store.dispatch('loadSocialReason')
      promise.then((res) => {
        this.social_reasons = res.listEntity
      })
          .catch(err => {
            console.log(err)
          })
    },
    validate: function () {
      this.loading = true;
      const promise = this.$store.dispatch('registerTransporter',
          {
            transporter: {
              name: this.transporter.name,
              telephone: this.transporter.telephone,
              licence: this.transporter.licence,
              email: this.transporter.email,
              'tree_item\\social_reason.id': this.transporter['tree_item\\social_reason.id'],
            }
          })
      promise.then((res) => {
        this.alert = {
          color: "warning",
          active: true,
          text: "Compte transporteur créé avec succès! Status: (" + res.transporter.status.label + "), Les administrateurs de la plateforme vont analysés les informations et activer votre compte. Cela peux prendre quelques jours.",
          icons: this.icons.mdiThumbUp
        }
        this.loading = false;
        this.transporter.id = res.transporter.id
        this.nextStep(1);
      })
          .catch(err => {
            console.log(err)
            this.alert = {
              color: "error",
              active: true,
              text: "Une Erreur c'est produite lors de la création !!!",
              icons: this.icons.mdiAlertRemove
            }
          })
    }
  }
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/pages/auth.scss';
.auth-wrapper.auth-v1 .auth-inner {
  width: 50rem !important;
  z-index: 1;
}
</style>