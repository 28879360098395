<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        :persistent="dialog"
        overlay-color="black"
        width="400"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          {{loader.text}}
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['loader'],
  name: "loaderdialog",
    data () {
      return {
        dialog: this.loader.power,
      }
    },
  }
</script>
<!-- <style>
    .v-overlay {
      z-index: 100000 !important;
    }
</style> -->