<template>

  <v-card elevation="0" color="basil">
    <v-card-title class="text-center justify-center py-6">
        <div class="d-flex flex-no-wrap justify-space-between">
          <div>
            <v-card-title
                class="text-h5"
                v-text="transporter.name"
            ></v-card-title>
            <v-card-subtitle>
              {{transporter.supplement}}
            </v-card-subtitle>

            <v-card-actions>
              <v-btn
                  class="ml-2 mt-5"
                  outlined
              >
                Paramètres du compte
              </v-btn>
            </v-card-actions>
          </div>

          <v-avatar
              class="ma-3"
              size="150"
              tile
          >
            <v-img :src="'assets/images/send-package.png'"></v-img>
            <br>
            <v-btn
                small
                class="ml-2 mt-5"
            >
              Modifier le logo
            </v-btn>
          </v-avatar>
        </div>
    </v-card-title>

    <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
        grow
    >
      <v-tab
          v-for="item in items"
          :key="item.key"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
          key="general"
      >
        <v-card
            color="basil"
            flat
        >
          <v-card-title>Vue d'ensemble</v-card-title>
        </v-card>
      </v-tab-item>
      <v-tab-item
          key="manager_order"
      >
        <v-card
            color="basil"
            flat
        >
          <v-card-title>Gestion des commandes</v-card-title>
          <order-list :transporter="transporter" :countries="countries" ></order-list>
        </v-card>
      </v-tab-item>
      <v-tab-item
          key="tariff"
      >
        <v-card
            color="basil"
            flat
        >
          <v-card-title>Gestion du tariffaire</v-card-title>
          <tariff :transporter="transporter" :countries="countries" :user="user"/>
        </v-card>
      </v-tab-item>
      <v-tab-item
          key="config"
      >
        <v-card
            color="basil"
            flat
        >
          <v-card-title>Configuration des données</v-card-title>
          <v-expansion-panels>

            <v-expansion-panel
                key="affiliate"
            >
              <v-expansion-panel-header>
                Affiliation (sous traitant)
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <affiliate-manager :countries="countries" :transporter="transporter"/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
                key="agency"
            >
              <v-expansion-panel-header>
                Agence / Point relais
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <relay-point :countries="countries" :transporter="transporter"/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
                key="interval"
            >
              <v-expansion-panel-header>
                Interval
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <interval-weight :transporter="transporter"/>
                <interval-price :transporter="transporter"/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
                key="interval"
            >
              <v-expansion-panel-header>
                Gestion des couts supplémentaires
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <supplement :transporter="transporter"/>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel
                key="countries"
            >
              <v-expansion-panel-header>
                Pays déservi
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <country-check :transporter="transporter"/>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-col
          >
          </v-col>
        </v-card>
      </v-tab-item>

    </v-tabs-items>
  </v-card>

</template>

<script>
import Tariff from "../components/Tariff";
import {Drequest} from "../plugins/Drequest";
import RelayPoint from "../components/Relaypoint";
import IntervalWeight from "../components/IntervalWeight";
import IntervalPrice from "../components/IntervalPrice";
import AffiliateManager from "../components/AffiliateManager";
import CountryCheck from "../components/CountryCheck";
import OrderList from "./OrderList";

export default {
  name: "Transporter",
  components: {OrderList, CountryCheck, AffiliateManager, IntervalPrice, IntervalWeight, RelayPoint, Tariff},
  props: ["user"],
  data: () => ({
    items: [
      {name: "Vue générale", key: "general"},
      {name: "Gestion des course", key: "manage_order"},
      {name: "Gestion du tariffaire", key: "tariff"},
      {name: "Configuration", key: "config"},
    ],
    tab: {},
    transporter: {},
    countries: [],
  }),
  beforeMount() {
    Drequest.api("transporter.detail?id=" + this.user.transporter_id)
        .get((response) => {
      console.log(response);
      this.transporter = response.transporter;
      this.countries = response.countries
    })
  },
  mounted() {
    this.tab = this.items[0];
  }
}
</script>

<style scoped>

</style>