import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store.js'
//import Home from '../views/Home.vue'
//import EditProfile from "../views/EditProfile";
import RegisterPro from "../views/RegisterPro";
import InitRegister from "../views/InitRegister";
import Transporter from "../views/Transporter";
import Map from "../views/pages/Map";
//import i18n from "../i18n";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'application',
    //redirect: `/${i18n.locale}/'application'`,
  },
  {
    path: '/:lang',
    component: {
      render(c){
        return c('router-view')
      }
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/sendcolis',
        name: 'sendcolis',
        component: () => import('@/views/SendColis.vue'),
        layout: 'content',
        requiresAuth: true
      },
      {
        path: '/sendOderTaxi',
        name: 'sendodertaxi',
        component: () => import('@/views/SendOderTaxi.vue'),
        layout: 'content',
        requiresAuth: true
      },
      {
        path: '/settings/profile',
        name: 'profile',
        component: () => import('@/views/settings/AccountSettings.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/affiliation',
        name: 'affiliation',
        component: () => import('@/views/settings/Affiliation.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/detail/course/:id',
        name: 'course_detail',
        component: () => import('@/views/DetailCourse.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/course/:id',
        name: 'detail_course',
        component: () => import('@/views/CourseDetail.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/affiliate/:id',
        name: 'affiliate',
        component: () => import('@/views/Affiliate.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/map/',
        name: 'google_maps',
        component: Map,
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/agencies',
        name: 'agency',
        component: () => import('@/views/settings/Agency.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/packages',
        name: 'packages',
        component: () => import('@/views/settings/Package.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/interval',
        name: 'interval',
        component: () => import('@/views/settings/Interval.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/tarifaire',
        name: 'tarifaire',
        component: () => import('@/views/settings/Tarifaire.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/course',
        name: 'course',
        component: () => import('@/views/settings/Course.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/costs',
        name: 'cost',
        component: () => import('@/views/settings/Cost.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/settings/country',
        name: 'country',
        component: () => import('@/views/settings/Country.vue'),
        meta: {
          layout: 'content',
          requiresAuth: true
        },
      },
      {
        path: '/auth/init',
        name: 'Init',
        component: InitRegister,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: '/auth/register-pro',
        name: 'RegisterPro',
        component: RegisterPro,
        meta: {
          layout: 'content',
          requiresAuth: false
        },
      },
      {
        path: '/transporter',
        name: 'Transporter',
        component: Transporter
      },
      {
        path: '/pages/tracking',
        name: 'pages-tracking',
        component: () => import('@/views/pages/Tracking.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: '/auth/activation',
        name: 'auth-activadtion',
        component: () => import('@/views/pages/Activation.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: '/auth/login',
        name: 'auth-login',
        component: () => import('@/views/pages/Login.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: '/auth/register',
        name: 'auth-register',
        component: () => import('@/views/pages/Register.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: '/application',
        name: 'application',
        component: () => import('@/views/pages/Application.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error.vue'),
        meta: {
          layout: 'blank',
        },
      },
      {
        path: '*',
        redirect: 'error-404',
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to.matched)
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/auth/login')
  } else {
    next()
  }
})

export default router
