<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    style="background:white"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="auto"
          max-width="50px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            {{$t('app_title')}}
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
      style="margin-bottom:70px"
    >
      <nav-menu-link
        :title="$t('navbar.dashboard')"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiShieldHome"
      ></nav-menu-link>
      <nav-menu-section-title :title="$t('menu.professionnal')"></nav-menu-section-title>
      <nav-menu-link
          v-if="!isTransporter"
          :title="$t('navbar.comepro')"
          :to="{ name: 'Init' }"
          :icon="icons.mdiTextBoxCheck"
      ></nav-menu-link>
      <nav-menu-link
          :title="$t('navbar.mypackages')"
          :to="{ name: 'packages' }"
          :icon="icons.mdiTextBoxCheck"
      ></nav-menu-link>

      <nav-menu-section-title v-if="isTransporter" :title="$t('menu.activities')"></nav-menu-section-title>
      <nav-menu-link
        v-if="isTransporter"
        :title="$t('navbar.course')"
        :to="{ name: 'course' }"
        :icon="icons.mdiTextBoxCheck"
      ></nav-menu-link>
      <nav-menu-link
          v-if="isTransporter"
          :title="$t('navbar.map')"
          :to="{ name: 'google_maps' }"
          :icon="icons.mdiMapMarker"
      ></nav-menu-link>
      <nav-menu-link
        v-if="isTransporter"
        :title="$t('navbar.tarifaire')"
        :to="{ name: 'tarifaire' }"
        :icon="icons.mdiAlphaTCircle"
      ></nav-menu-link>
      <nav-menu-section-title v-if="isTransporter" :title="$t('menu.settings')"></nav-menu-section-title>
      <nav-menu-link
        v-if="isTransporter"
        :title="$t('navbar.affiliation')"
        :to="{ name: 'affiliation' }"
        :icon="icons.mdiTextBoxCheck"
      ></nav-menu-link>
      <nav-menu-link
        v-if="isTransporter"
        :title="$t('navbar.agency')"
        :to="{ name: 'agency' }"
        :icon="icons.mdiAlphaTCircle"
      ></nav-menu-link>
      <nav-menu-link
        v-if="isTransporter"
        :title="$t('navbar.interval')"
        :to="{ name: 'interval' }"
        :icon="icons.mdiAlphaACircle"
      ></nav-menu-link>
      <nav-menu-link
        v-if="isTransporter"
        :title="$t('navbar.cost')"
        :to="{ name: 'cost' }"
        :icon="icons.mdiAlphaACircle"
      ></nav-menu-link>
      <nav-menu-link
        v-if="isTransporter"
        :title="$t('navbar.country')"
        :to="{ name: 'country' }"
        :icon="icons.mdiAlphaACircle"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiShieldHome,
  mdiTextBoxCheck,
    mdiMapMarker,
  mdiAlphaTCircle,
  mdiAlphaACircle,
  mdiCloudUploadOutline,
  mdiBookmark
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
//import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    NavMenuSectionTitle,
   // NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data : ()=>({
      dialog: false,
      icons: {
        mdiShieldHome,
        mdiTextBoxCheck,
        mdiMapMarker,
        mdiAlphaTCircle,
        mdiAlphaACircle,
        mdiCloudUploadOutline,
        mdiBookmark
      },
  }),
  computed: {
    ...mapGetters([
        'isTransporter'
    ])
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}   

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
