<template>
  <div class="container mt-5 mb-3">
    <div class="row d-flex justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="d-flex flex-row p-2">
            <table class="table table-borderless">
              <tbody>
              <tr class="content">
                <div class="d-flex justify-content-between">
                  <div class="amount">
                    <h4>Transporter: {{ orderdetail.transporter.name }}</h4>
                    Téléphone: <span class="text-primary font-weight-bold">{{ orderdetail.transporter.telephone }}</span> <br/>
                    Email: <small>{{ orderdetail.transporter.email }}</small>
                  </div>
                  <div class="d-flex flex-row align-items-center">
                    <img :src="orderdetail.transporter.urlImage" width="100" class="rounded-circle">
                  </div>
                </div>
              </tr>
              </tbody>
            </table>
          </div>
          <hr>
          <div class="d-flex flex-row p-2">
            <table class="table table-borderless">
              <tbody>
              <tr class="content">
                <td class="font-weight-bold" style="font-size: 15px">
                  Colis N° <small>{{orderdetail.id}}</small> du <small>{{orderdetail.created_at}}</small> <br/>
                  N° Fact.: <small>{{ orderdetail.num_facture }}</small><br/>
                  Code de tracking: <small>{{orderdetail.tracking_code}}</small><br/>
                  Ref. paiement: <small>{{orderdetail.paymentreference}}</small><br/>
                  Mode de livraison: <small>{{orderdetail.deliverymode.name}}</small><br/>
                </td>
                <td class="font-weight-bold"><v-img width="120" :src="orderdetail.qrcode"></v-img></td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr>
          <div class="table-responsive p-2">
            <table class="table table-borderless">
              <tbody>
              <tr class="add">
                <td>Expediteur</td>
                <td>Receveur</td>
              </tr>
              <tr class="content">
                <td class="font-weight-bold">{{ orderdetail.sender.firstname }} <br> Pays: {{ orderdetail.sender.country.iso }} <br> Tél: {{ orderdetail.sender.phonenumber }} <br> Ville:{{ orderdetail.sender.town }} <br> Adresse: {{ orderdetail.sender.address }} <br> BP: {{ orderdetail.sender.postalcode }}</td>
                <td class="font-weight-bold">{{ orderdetail.receiver.firstname }} <br> Pays: {{ orderdetail.receiver.country.iso }} <br> Tél: {{ orderdetail.receiver.phonenumber }} <br> Ville:{{ orderdetail.receiver.town }} <br> Adresse: {{ orderdetail.receiver.address }} <br> BP: {{ orderdetail.receiver.postalcode }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr>
          <div class="products p-2">
            <table class="table table-borderless">
              <tbody>
              <tr>
                <footer id="footer">
                  <div class="container">
                    <div class="credits"> Designed by <span class="colorCredit">BuyamSellam Logistics</span> </div>
                  </div>
                </footer>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

//import VueHtml2pdf from 'vue-html2pdf'
export default {
  name: "FicheColis",
  props: ['orderdetail'],
  /*methods: {
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  },

  components: {
    VueHtml2pdf
  }*/
}
</script>

<style scoped>
  @import "../assets/css/bootstrap5.min.css";
  @import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');

  body {
    font-family: 'Maven Pro', sans-serif;
    background-color: #f12369
  }

  hr {
    color: rgba(101, 96, 96, 0.31);
    margin-top: 5px;
    margin-bottom: 5px
  }

  .add {
    color: #827f7f;
    text-transform: uppercase;
    font-size: 12px
  }
  .colorCredit {
    color: #0d6efc;
  }
.content {
    font-size: 14px
  }

  #footer {
    background: #404040;
    padding: 0 0 5px 0;
    color: #fff;
    font-size: 14px
  }

  #footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #fff
  }
</style>