import Vue from 'vue'
import Vuex from 'vuex'
import {Drequest} from './plugins/Drequest'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    stepper : {
        step:0,
        label:''
    },
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    transporter: {},
    customer_location: {},
    customer_address: {},
    social_raison: [],
    coverages: [],
    supplements: [],
    intervalprices: [],
    intervalweights: [],
    deliverymodes: [],
    items: [],
    countries: [],
      statues: []
  },
  mutations: {
    initialiseStore(state) {
        // Check if the ID exists
        if(localStorage.getItem('store')) {
            // Replace the state object with the stored item
            this.replaceState(
                Object.assign(state, JSON.parse(localStorage.getItem('store')))
            );
        }
    },
    auth_request(state){
        state.status = 'loading'
    },

    auth_success(state, payload){
        state.status = 'success'
        state.token = payload.token
        state.user = payload.user
        state.transporter = payload.transporter
        for (const [key, value] of Object.entries(payload)){
            localStorage.setItem(key, JSON.stringify(value));
        }
    },
    auth_error(state){
        state.status = 'error'
    },
    
    success(state){
        state.status = 'success'
    },
    logout(state){
        state.status = ''
        state.token = ''
        state.user = {}
        state.transporter = {}
    },
      setStepper(state, payload){
        state.stepper.step = payload.step;
        state.stepper.label = payload.label;
      },
      init_user(state, payload){
        state.user = payload
      },
      save_customer_location(state, payload){
        state.customer_location = payload;
      },
      save_customer_address(state, payload){
        state.customer_address = payload;
      },
      init_transporter(state, payload){
          state.transporter = payload
      },
    load_social_raison(state, payload){
        state.social_raison = payload
    },
    register_transporter(state, payload){
        state.transporter = payload
        localStorage.setItem("transporter", JSON.stringify(payload));
    },
    init_tarif(state, payload){
        state.coverages = payload.coverages
        state.supplements = payload.supplements
        state.intervalprices = payload.intervalprices
        state.intervalweights = payload.intervalweights
        state.items = payload.items,
        state.statues = payload.statues
    },
    init_countries(state, payload){
        state.countries = payload
    },
    init_deliverymodes(state, payload){
        state.deliverymodes = payload
    },
    init_intervalWeight(state, payload){
        state.intervalweights = payload
    },
    init_intervalPrice(state, payload){
        state.intervalprices = payload
    },
  },
  actions: {
    initIntervalPrice({commit,state}){
        return new Promise((resolve) => {
          Drequest.api(`lazyloading.price-interval?dfilters=on&transporter.id:eq=${state.transporter.id}`)
          .get((response) => {
              commit('init_intervalPrice', response)
              resolve(response)
          })
        })
      },
    initIntervalWeight({commit,state}){
        return new Promise((resolve) => {
          Drequest.api(`lazyloading.weight-interval?dfilters=on&transporter.id:eq=${state.transporter.id}`)
          .get((response) => {
              commit('init_intervalWeight', response)
              resolve(response)
          })
        })
      },
    countriesCheck({state}){
      return new Promise((resolve) => {
        Drequest.api(`countries?id_transporter=${state.transporter.id}`)
        .get((response) => {
            resolve(response.countries)
        })
      })
    },
    initTarif({commit, state}){
        return new Promise((resolve) => {    
            Drequest.api("dcollection")
                .data({
                    "lazyloading.tarrif": `dfilters=on&transporter.id:eq=${state.transporter.id}`,
                    "lazyloading.weight-interval": `dfilters=on&transporter.id:eq=${state.transporter.id}`,
                    "lazyloading.price-interval": `dfilters=on&transporter.id:eq=${state.transporter.id}`,
                    "lazyloading.supplementprice": `dfilters=on&transporter.id:eq=${state.transporter.id}`,
                    "lazyloading.status": "dfilters=on&entity.name:eq=order",
                    "lazyloading.tree-item": `dfilters=on&tree.name:eq=coverage`,
                })
                .raw((response) => {
                    commit('init_tarif', 
                        {
                            coverages:response["tree-item_ll"].listEntity,
                            supplements:response["supplementprice_ll"].listEntity,
                            intervalprices:response['price-interval_ll'].listEntity,
                            intervalweights:response['weight-interval_ll'].listEntity,
                            items:response.tarrif_ll.listEntity,
                            tariff_ll:response.tarrif_ll,
                            statues: response["status_ll"].listEntity
                        })
                        resolve(response)
            })

            Drequest.api("lazyloading.tree-item?dfilters=on&tree.name:eq=deliverymode")
                .get((response) => {
                    commit('init_deliverymodes',response.listEntity)
                    resolve(response)
                    //data.deliverymodes = response.listEntity;
            })

            Drequest.api(`transporter-countries?id_transporter=${state.transporter.id}`)
                .get((response) => {
                    commit('init_countries',response.countries)
                    resolve(response)
                   // data.countries = response.countries
            })
        })
    },
    activeCode({commit, state}, code){
        return new Promise((resolve, reject) => {
            commit('auth_request')
            Drequest.api(`user.activateaccount?user_id=${JSON.parse(state.user).id}`)
            .data(code)
            .post((response) => {
              switch (response.success) {
                  case true:
                      commit('auth_success', {token:response.success})
                      resolve(response)
                      break;
              
                  default:
                      commit('auth_error', response)
                      localStorage.removeItem('token')
                      reject(response.error)
                      break;
              }
            })
            .catch(err => {
              commit('auth_error', err)
              reject(err)
            })
          })
    },
    login({commit, dispatch}, user){
        let token = null;
        return new Promise((resolve, reject) => {
          commit('auth_request')
          Drequest.api("user.authentification")
          .data(user)
          .post((response) => {
            switch (response.success) {
                case true:
                    response.user.is_activated == 1 ? token = true : token = null;
                    commit('auth_success', {token:token, user: JSON.stringify(response.user), transporter: JSON.stringify(response.transporter)})
                    commit('register_transporter', response.transporter)
                    // response.user.transporter_id !==null ? dispatch('loadTransporter') : false;
                    console.log(dispatch)
                    resolve(response)
                    break;
            
                default:
                    commit('auth_error', response)
                    localStorage.removeItem('token')
                    reject(response.error)
                    break;
            }
          })
          .catch(err => {
            commit('auth_error', err)
            reject(err)
          })
        })
    },
      register({commit}, user){
        return new Promise((resolve, reject) => {
          commit('auth_request')
          Drequest.api("user.register")
          .data(user)
          .raw((response) => {
            switch (response.success) {
                case true:
                    commit('auth_success', {token:null, user: JSON.stringify(response.user)})
                    resolve(response);
                    break;
            
                default:
                    commit('auth_error', response)
                    localStorage.removeItem('token')
                    reject(response)
                    break;
            }
            reject()
        })
        })
      },
      registerTransporter({commit, state}, data){
          data.transporter["user.id"] = JSON.parse(state.user).id;
        return new Promise((resolve, reject) => {
            Drequest.api("create.transporter")
            .data(data)
            .raw((response) => {
                switch (response.success) {
                    case true:
                        commit('register_transporter', response.transporter)
                        resolve(response);
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      deletePriceInterval({commit},payload){
        return new Promise((resolve, reject) => {
            Drequest
            .api(`delete.price-interval?id=${payload.id}`)
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        commit('success')
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      deleteWeightInterval({commit},payload){
        return new Promise((resolve, reject) => {
            Drequest
            .api(`delete.weight-interval?id=${payload.id}`)
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        commit('success')
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      updateWeightInterval({commit},payload){
        return new Promise((resolve, reject) => {
            Drequest
            .api(`update.weight-interval?id=${payload.id}`)
            .data({weight_interval:payload})
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        commit('success')
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      updatePriceInterval({commit},payload){
        return new Promise((resolve, reject) => {
            Drequest
            .api(`update.price-interval?id=${payload.id}`)
            .data({price_interval:payload})
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        commit('success')
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      updateCost({commit},payload){
        return new Promise((resolve, reject) => {
            Drequest
            .api(`update.supplementprice?id=${payload.id}`)
            .data({supplementprice:payload})
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        commit('success')
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      savePriceInterval({state}, price){
        price.price_interval['transporter.id'] = state.transporter.id
        return new Promise((resolve, reject) => {
            Drequest
            .api("create.price-interval")
            .data(price)
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      saveWeightInterval({state}, weight){
        weight.weight_interval['transporter.id'] = state.transporter.id
        return new Promise((resolve, reject) => {
            Drequest
            .api("create.weight-interval")
            .data(weight)
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      saveCost({state}, cost){
        cost.supplementprice['transporter.id'] = state.transporter.id
        return new Promise((resolve, reject) => {
            Drequest
            .api("create.supplementprice")
            .data(cost)
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      updateTarif({commit},payload){
        return new Promise((resolve, reject) => {
            Drequest
            .api(`update.tarrif?id=${payload.id}`)
            .data({tarrif:payload})
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        commit('success')
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      saveTarif({state}, tar){
        tar.tarrif['transporter.id'] = state.transporter.id
        console.log(tar)
        return new Promise((resolve, reject) => {
            Drequest
            .api("create.tarrif")
            .data(tar)
            .raw((response)=>{
                switch (response.success) {
                    case true:
                        resolve(response)
                        break;
                
                    default:
                        reject(response)
                        break;
                }
            })
        })
      },
      loadSocialReason({commit}){
        return new Promise((resolve, reject) => {
            Drequest.api('lazyloading.tree-item?dfilters=on&tree.name:eq=social_reason')
                .get((response) => {
                    switch (response.success) {
                        case true:
                            commit('load_social_raison', response.listEntity)
                            resolve(response);
                            break;
                    
                        default:
                            reject(response);
                            break;
                    }
                })
        })
      },
      loadCost({state}){
        return new Promise((resolve, reject) => {
            Drequest.api(`lazyloading.supplementprice?dfilters=on&transporter.id:eq=${state.transporter.id}`)
            .get((response) => {
                switch (response.success) {
                    case true:
                        resolve(response);
                        break;
                
                    default:
                        reject(response);
                        break;
                }
            })
        })
      },
      loadTransporter({commit, state}){
        return new Promise((resolve, reject) => {
            Drequest.api(`lazyloading.transporter?dfilters=on&transporter.id:eq=${JSON.parse(state.user).transporter_id}`)
            .get((response) => {
                switch (response.success) {
                    case true:
                        commit('register_transporter', response.listEntity[0])
                        resolve(response);
                        break;
                
                    default:
                        reject(response);
                        break;
                }
            })
        })
      },
      logout({commit}){
        return new Promise((resolve, reject) => {
          commit('logout')
          localStorage.removeItem('token')
          localStorage.removeItem('user')
          // delete axios.defaults.headers.common['Authorization']
          resolve()
          reject()
        })
      }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    isTransporter: state => {
        if(state.transporter.status){
            return state.transporter.status._key == "approved";
        }else{
            return false;
        }
    },
    authStatus: state => state.status,
    user: state => state.user
  }
})

store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
});

export default store