import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import i18n from './i18n'

import Blank from './layouts/Blank.vue';
import Content from './layouts/Content.vue';

Vue.component('blank-layout', Blank);
Vue.component('content-layout', Content);
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyBdHtCszsxBCg5EZnF6uXNuucr--mHoelg',
            libraries: 'places', // This is required if you use the Autocomplete plugin
            // OR: libraries: 'places,drawing'
            // OR: libraries: 'places,drawing,visualization'
            // (as you require)

            //// If you want to set the version, you can do so:
            // v: '3.26',
        },
    }
)
Vue.config.productionTip = false

/*router.beforeEach((to, from, next)=> {
    let language = to.params.lang;
    if(!language){
        language = 'fr'
    }
    i18n.locale = language;
    next()
}) */

new Vue({
    vuetify,
    router,
    i18n,
    store,
    render: h => h(App),
    beforeCreate() {
        this.$store.commit('initialiseStore');
    }
}).$mount('#app')
